import { ShippingExtensions as Base } from 'core/extensions/ShippingExtensions'
import { Places, ShippingParcel, TmrPlace } from 'stylewhere/api'
import { FormSchema, FormSchemaData, getFieldValue, OperationConfig, ShippingOperationConfig } from 'stylewhere/shared'

export class ShippingExtensions extends Base {
  static formSchema(
    operation: ShippingOperationConfig,
    additionalData?: { parcel?: ShippingParcel },
    filterKey?: string[]
  ): FormSchema {
    const schema = Base.formSchema(operation, additionalData, filterKey)

    if (operation.code === 'inbound-warehouse' && additionalData?.parcel !== undefined) {
      schema.forEach((field) => {
        //cerca automaticamente i campi dentro il parcel e nel caso prefilla i defaultvalue
        const defaultValue = getFieldValue(additionalData.parcel, field)
        if (defaultValue) field.defaultValue = defaultValue
      })
    }
    return schema
  }

  static async beforeConfirm<O>(operation: OperationConfig, data: FormSchemaData, objects: O[]): Promise<boolean> {
    if (
      operation.code === 'inbound-warehouse' &&
      data.asn &&
      data.asn.originPlaceId &&
      data.asn.originPlaceId !== null
    ) {
      const place = await Places.get<TmrPlace>(data.asn.originPlaceId)
      if (place) {
        data.attributes.unloadPlaceCode = place.code
      }
      return true
    }
    return super.beforeConfirm(operation, data, objects)
  }
}
